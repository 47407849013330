body {
  background: #313131 !important;
  /*background: white !important;*/
  overflow: hidden;
}

#root {
  font-family: monospace !important;
}

#root > div:first-child {
  position: relative !important;
  width: 100% !important;
  margin: 0px 0px !important;
  max-width: 100% !important;
  font-size: 14px !important;
}

#root > div:first-child > div:first-child {
  border: 1px solid #262626;
  border-radius: 0px;
}

#root > div:first-child > div:nth-child(2) {
  height: 100vh !important;
  max-height: 100vh !important;
  border: 1px solid #313131;
  border-radius: 0px;
  box-shadow: 0px 0px 0px 0px #313131 !important;
}

#root > div:first-child > div:nth-child(2) > div:first-child {
  font-family: monospace !important;
  padding-bottom: 13vh;
}

#root > div:first-child > div:nth-child(2) > div:first-child > div:first-child > div {
  word-break: normal !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
